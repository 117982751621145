<template>
  <div class="db-statistics">
    <download-excel
      class="ui-button ui-button-auto"
      :data="json_data"
      :fields="json_fields"
      type="xls"
      name="filename.xls"
    >
      {{ $t('statistics_view.download_xls') }}
    </download-excel>

    <div class="db-statistics__filters">
      <div class="ui-form-group">
        <label class="ui-label">{{ $t('statistics_view.select_dates') }}</label>
        <vue-date-picker
          locale="ru"
          @update:model-value="applyDateRange"
          range
          :multi-calendars="{ solo: true }"
          v-model="filters.dates"
          :dark="$themeStore.isDarkTheme"
        />
      </div>
    </div>

    <div class="db-statistics__grid-1">
      <div class="db-statistics__widget db-statistics__credits">
        <h3>{{ $t('statistics_view.general_cost_schedule') }}</h3>

        <div>
          <bar-chart
            :chart-data="chartData"
            width="1120"
            height="300"
          />
        </div>
      </div>
    </div>

    <div class="db-statistics__filters">
      <div class="ui-form-group">
        <label class="ui-label">{{ $t('statistics_view.managers') }}</label>
        <multi-select-wr
          v-model="multiselect.managerObject"
          :placeholder="$t('statistics_view.select_managers')"
          label="name"
          track-by="id"
          :options="multiselect.managers"
          multiple
          searchable
          @select="selectManager"
          @remove="removeManager"
          required
        />
      </div>
      <div class="ui-form-group">
        <label class="ui-label">{{ $t('statistics_view.clients') }}</label>
        <multi-select-wr
          v-model="multiselect.clientObject"
          :placeholder="$t('statistics_view.select_clients')"
          label="company"
          track-by="id"
          :options="multiselect.clients"
          multiple
          searchable
          allow-empty
          @select="selectClient"
          @remove="removeClient"
          required
        />
      </div>
      <div class="ui-form-group" v-if="filtersData.eventStatuses.length">
        <label class="ui-label">{{ $t('statistics_view.event_statuses') }}</label>
        <multi-select-wr
          v-model="multiselect.eventStatusObject"
          :placeholder="$t('statistics_view.select_status')"
          label="name"
          track-by="id"
          :options="multiselect.eventStatuses"
          @select="selectEventStatus"
          @remove="removeEventStatus"
          required
        />
      </div>
      <div class="ui-form-group" v-if="filtersData.creditStatuses.length">
        <label class="ui-label">{{ $t('statistics_view.expense_statuses') }}</label>
        <multi-select-wr
          v-model="multiselect.creditStatusObject"
          :placeholder="$t('statistics_view.select_status')"
          label="name"
          track-by="id"
          :options="multiselect.creditStatuses"
          @select="selectCreditStatus"
          @remove="removeCreditStatus"
          required
        />
      </div>
      <div class="ui-form-group" v-if="filtersData.debitStatuses.length">
        <label class="ui-label">{{ $t('statistics_view.receipt_statuses') }}</label>
        <multi-select-wr
          v-model="multiselect.debitStatusObject"
          :placeholder="$t('statistics_view.select_status')"
          label="name"
          track-by="id"
          :options="multiselect.debitStatuses"
          @select="selectDebitStatus"
          @remove="removeDebitStatus"
          required
        />
      </div>
    </div>

    <div class="db-statistics__list">
      <div class="db-statistics__item">
        <p class="ui-label">{{ $t('statistics_view.receipts') }}</p>
        <h2>{{ $convertToCurrency(statistics.debit) }}</h2>
      </div>
      <div class="db-statistics__item">
        <p class="ui-label">{{ $t('statistics_view.expenses') }}</p>
        <h2>{{ $convertToCurrency(statistics.credits) }}</h2>
      </div>
      <div class="db-statistics__item">
        <p class="ui-label">{{ $t('statistics_view.remainder') }}</p>
        <h2>{{ $convertToCurrency(statistics.total) }}</h2>
      </div>
    </div>

    <div class="db-statistics__grid-2">
      <div
        class="db-statistics__widget db-statistics__credits"
        v-if="credit_statistics && credit_statistics.length"
        :key="credit_statistics_key"
      >
        <h3>{{ $t('statistics_view.expense_statistics') }}</h3>
        <table>
          <tr>
            <td>{{ $t('statistics_view.flow_type') }}</td>
            <td>{{ $t('statistics_view.sum') }}</td>
          </tr>
          <tr
            v-for="item in credit_statistics"
            :key="item.id"
            v-show="item.sum > 0"
          >
            <td>{{ item.name }}</td>
            <td>{{ $convertToCurrency(item.sum) }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelectWr from "@/global-components/MultiSelectWr.vue";
import DownloadExcel from "vue-json-excel3";
import BarChart from './components/BarChart.vue';

export default {
  components: {
    DownloadExcel,
    MultiSelectWr,
    BarChart
  },
  data() {
    return {
      multiselect: {
        clients: [],
        clientObject: [],
        managers: [],
        managerObject: [],
        eventStatuses: [],
        eventStatusObject: null,
        creditStatuses: [],
        creditStatusObject: null,
        debitStatuses: [],
        debitStatusObject: null,
      },
      events: [],
      expenses: {},
      debits: {},
      eventsFiltred: [],
      filters: {
        users: [],
        clients: [],
        managers: [],
        eventStatus: 0,
        creditStatus: 0,
        debitStatus: 0,
        dates: 0
      },
      filtersData: {
        managers: [],
        clients: [],
        eventStatuses: [],
        creditStatuses: [],
        debitStatuses: [],
      },
      filtersDisplay: {
        managers: false,
        clients: false,
        eventStatuses: false,
        creditStatuses: false,
        debitStatuses: false,
      },
      statistics: {
        debit: 0,
        credits: 0,
        total: 0
      },
      credit_statistics_key: 0,
      credit_statistics: [],
      credit_types: [],
      json_data: null,
      json_fields: {
        'Дата': 'date',
        'Клиент': 'company',
        'Событие': 'event',
        'Тип операции': 'operation_type',
        'Название операции': 'operation',
        'Поступление': 'income',
        'Расход': 'outcome',
        'Статус операции': 'status',
        'Баланс': 'balance'
      }
    }
  },
  computed: {
    chartData () {
      return {
        labels: Object.keys(this.expenses).reduce((acc, label) => {
          acc.push(this.$t(`months.${label}`))

          return acc
        }, []),
        datasets: [
          {
            label: this.$t('statistics_view.expenses'),
            backgroundColor: '#f87979',
            data: Object.values(this.expenses)
          },
          {
            label: this.$t('statistics_view.receipts'),
            backgroundColor: '#79f8b4',
            data: Object.values(this.debits)
          }
        ]
      }
    }
  },
  methods: {
    applyDateRange(d) {
      if (d) {
        this.filters.dates = [d[0], d[1]]
      } else {
        this.filters.dates = 0
      }
      
      this.filter()
    },
    async calculateCreditStatistics(creditIds) {
      this.credit_statistics = []

      this.credit_statistics = this.credit_types.map(creditType => {
        return {
          id: creditType.id,
          name: creditType.name,
          sum: 0
        }
      })

      this.eventsFiltred.forEach(event => {
        if (!event.credits.length) return;
        event.credits.forEach(credit => {
          if (!creditIds.length || creditIds.indexOf(credit.id) === -1) return;
          this.credit_statistics.forEach(item => {
            if (!credit.credit_type) return;
            if (item.id === credit.credit_type.id) {
              item.sum += credit.sum
            }
          })
        })
      })

      this.credit_statistics_key += 1
    },
    async getCreditTypes() {
      try {
        const { data } = await this.$api.get('/v1/credit-type/list')
        if (data) this.credit_types = data
      } catch (e) {
        console.log(e, this.$t('statistics_view.errors.failed_get_expense_types'))
      }
    },
    selectEventStatus(status) {
      this.filters.eventStatus = status.id
      this.filter()
    },
    removeEventStatus() {
      this.filters.eventStatus = null
      this.filter()
    },
    selectCreditStatus(status) {
      this.filters.creditStatus = status.id
      this.filter()
    },
    removeCreditStatus() {
      this.filters.creditStatus = null
      this.filter()
    },
    selectManager() {
      this.filters.managers = this.multiselect.managerObject.map(manager => {
        return manager.id
      })

      this.filter()
    },
    removeManager() {
      if (!this.multiselect.managerObject.length) {
        this.filters.managers = []
        this.filter()
        return
      }

      this.filters.managers = this.multiselect.managerObject.map(manager => {
        return manager.id
      })
      this.filter()
    },
    selectClient() {
      this.filters.clients = this.multiselect.clientObject.map(client => {
        return client.id
      })

      this.filter()
    },
    removeClient() {
      if (!this.multiselect.clientObject.length) {
        this.filters.clients = []
        this.filter()
        return
      }

      this.filters.clients = this.multiselect.clientObject.map(client => {
        return client.id
      })

      this.filter()
    },
    selectDebitStatus(status) {
      this.filters.debitStatus = status.id;
      this.filter();
    },
    removeDebitStatus() {
      if (!this.multiselect.debitStatusObject.length) {
        this.filters.debitStatus = null
        this.filter()
        return
      }

      this.selectDebitStatus()
    },
    filter() {
      let eventsFiltred = JSON.parse(JSON.stringify(this.events))

      if (this.filters.clients.length) {
        eventsFiltred = eventsFiltred.filter(item => {
          if (this.filters.clients.indexOf(item.client.id) > -1) {
            return item
          }
        })
      }

      if (this.filters.managers.length) {
        eventsFiltred = eventsFiltred.filter(item => {
          if (this.filters.managers.indexOf(item.manager.id) > -1) {
            return item
          }
        })
      }
      if (this.filters.dates) {
        eventsFiltred = eventsFiltred.map((e) => {
          e.debits = e.debits.filter(d => new Date(d.createdAt).getTime() >= new Date(this.filters.dates[0]).getTime() && new Date(d.createdAt).getTime() <= new Date(this.filters.dates[1]).getTime())
          e.credits = e.credits.filter(c => new Date(c.createdAt).getTime() >= new Date(this.filters.dates[0]).getTime() && new Date(c.createdAt).getTime() <= new Date(this.filters.dates[1]).getTime())

          return e
        })
      }

      this.eventsFiltred = eventsFiltred

      this.calculate()

      this.json_data = []
      let item;

      for (const event of this.eventsFiltred) {
        item = {
          date: this.$convertDate(event.createdAt),
          company: event?.client?.contact?.company,
          event: '',
          operation_type: '',
          operation: '',
          income: '',
          outcome: '',
          status: '',
          balance: ''
        }
        this.json_data.push(item)

        let balance = 0

        let operations_by_event = []

        // debits
        if (event.debits && event.debits.length) {
          for (const debit of event.debits) {
            balance += debit.sum
            item = {
              date: this.$convertDate(debit.createdAt),
              company: event?.client?.contact.company,
              event: event.name,
              operation_type: this.$t('statistics_view.receipt'),
              operation: debit.name,
              income: debit.sum,
              otcome: '',
              status: this.$t(debit.status.name),
              balance: 0
            }

            operations_by_event.push({
              type: 'income',
              date: debit.createdAt,
              item
            })
          }
        }

        // credits
        if (event.credits && event.credits.length) {
          for (const credit of event.credits) {
            balance -= credit.sum

            item = {
              date: this.$convertDate(credit.createdAt),
              company: event?.client?.contact.company,
              event: event.name,
              operation_type: `${this.$t('statistics_view.expense')} ${credit.credit_type ? '(' + credit.credit_type.name + ')' : ''}`,
              operation: credit.name,
              income: '',
              outcome: (credit.sum) * -1,
              status: this.$t(credit.status.name),
              balance: 0
            }

            operations_by_event.push({
              type: 'outcome',
              date: credit.createdAt,
              item
            })
          }
        }

        operations_by_event = operations_by_event.sort((a, b) => new Date(a.date) - new Date(b.date));

        balance = 0
        for (const o of operations_by_event) {
          if (o.type === 'income') balance += o.item.income
          if (o.type === 'outcome') balance += o.item.outcome

          o.item.balance = balance

          this.json_data.push(o.item)
        }

        item = {
          date: '',
          company: '',
          event: '',
          operation_type: '',
          operation: '',
          income: '',
          outcome: '',
          status: '',
          balance: ''
        }
        this.json_data.push(item)
      }
    },
    async calculate() {
      let statistics = {
        debit: 0,
        credits: 0,
        total: 0
      }

      let creditIds = []

      this.eventsFiltred.forEach(event => {
        // let debitForEvent = 0

        // compute event
        if (this.filters.eventStatus && event.status.id !== this.filters.eventStatus) return;

        // compute debits
        if (event.debits.length) {
          event.debits.forEach(debit => {
            if (this.filters.debitStatus && this.filters.debitStatus !== debit.status.id) return;

            statistics.debit += debit.sum
            // debitForEvent += debit.sum
          })
        }

        // compute credits
        if (event.credits.length) {
          event.credits.forEach(credit => {
            if (this.filters.creditStatus && this.filters.creditStatus !== credit.status.id) return;
            creditIds.push(credit.id)

            statistics.credits += credit.sum
          })
        }

        statistics.total = statistics.debit - statistics.credits
      });

      this.statistics = statistics

      await this.calculateCreditStatistics(creditIds)
    },
    async getEvents() {
      try {
        const { data } = await this.$api.get('/v2/statistics/events')
        this.events = data?.events || []
        this.expenses = data?.expenses || {}
        this.debits = data?.debits || []
      } catch (e) {
        this.$toast(e, this.$t('statistics_view.errors.failed_get_events'))
      }
    },
    async getEventStatuses() {
      try {
        const { data } = await this.$api.get('/v1/event-status/list')
        this.filtersData.eventStatuses = data
      } catch (e) {
        this.$catch(e, this.$t('statistics_view.errors.failed_get_event_statuses'))
      }
    },
    async getCreditStatuses() {
      try {
        const { data } = await this.$api.get('/v1/credit-status/list')
        this.filtersData.creditStatuses = data
      } catch (e) {
        this.$catch(e, this.$t('statistics_view.errors.failed_get_expense_statuses'))
      }
    },
    async getDebitStatuses() {
      try {
        const { data } = await this.$api.get('/v1/debit-status/list')
        this.filtersData.debitStatuses = data
      } catch (e) {
        this.$catch(e, this.$t('statistics_view.errors.failed_get_receipt_statuses'))
      }
    },
    async getUsers() {
      try {
        let { data } = await this.$api.get('/v1/user/list')
        this.filtersData.users = data
      } catch (e) {
        this.$catch(e, this.$t('statistics_view.errors.failed_get_expense_statuses'))
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.getEvents(),
      this.getEventStatuses(),
      this.getCreditStatuses(),
      this.getDebitStatuses(),
      this.getCreditTypes(),
      this.getUsers()
    ]).then(() => {
      // Multiselect :: event statuses
      this.multiselect.eventStatuses.push({
        id: 0,
        name: this.$t('statistics_view.all_statuses')
      })
      this.multiselect.eventStatuses = this.multiselect?.eventStatuses?.concat(
        this.filtersData.eventStatuses.map(status => {
          return {
            id: status.id,
            name: status.name
          }
        })
      )

      // Multiselect :: credit statuses
      this.multiselect.creditStatuses.push({
        id: 0,
        name: this.$t('statistics_view.all_statuses')
      })
      this.multiselect.creditStatuses = this.multiselect?.creditStatuses?.concat(
        this.filtersData.creditStatuses.map(status => {
          return {
            id: status.id,
            name: status.name
          }
        })
      )

      // Multiselect :: debit statuses
      this.multiselect.debitStatuses.push({
        id: 0,
        name: this.$t('statistics_view.all_statuses')
      })
      this.multiselect.debitStatuses = this.multiselect?.debitStatuses?.concat(
        this.filtersData.debitStatuses.map(status => {
          return {
            id: status.id,
            name: status.name
          }
        })
      )

      // Multiselect :: clients
      this.filtersData.clients = this.filtersData.users?.filter(user => {
        if (user.role.name === 'Клиент') return user
      })
      this.multiselect.clients = this.filtersData.clients?.map(client => {
        return {
          id: client.id,
          company: client?.contact.company
        }
      })

      // Multiselect :: managers
      this.filtersData.managers = this.filtersData.users?.filter(user => {
        if (user.role.name === 'Менеджер' || user.role.name === "Компания") return user
      })
      this.multiselect.managers = this.filtersData.managers.map(client => {
        return {
          id: client.id,
          name: client?.contact.name
        }
      })

      const currentYear = new Date().getFullYear()
      const startYear = new Date(currentYear, 0, 1, 0, 0, 0, 0)
      const currentDate = new Date()
      this.filters.dates = [startYear, currentDate]

      // calculate by credit types
      // this.calculateCreditStatistics()
    })
      .finally(() => this.filter())
  }
}
</script>

<style src="./style.scss" lang="scss"/>

