import axios from "axios";
import moment from "moment";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { toast } from "vue3-toastify";
// import Popper from 'vue3-popper'
import VueDatePicker from "@vuepic/vue-datepicker";
import JsonExcel from "vue-json-excel3";
import Multiselect from "vue-multiselect";

import router from "./router";

import { createI18n } from "vue-i18n";
import App from "./App.vue";

import translations from "@/translations";

import "@vuepic/vue-datepicker/dist/main.css";
import "vue-multiselect/dist/vue-multiselect.css";
import "vue3-toastify/dist/index.css";

const app = createApp(App);
app.component("VueDatePicker", VueDatePicker);
app.component("MultiSelect", Multiselect);
app.component("downloadExcel", JsonExcel);
app.use(createPinia());
app.use(router);

app.config.productionTip = false

const i18n = createI18n({
  locale: localStorage.getItem("language") || "ru",
  messages: translations,
});

app.use(i18n);

// Вспомогательные функции
const convertDateFn = (date) => {
  return moment(date).format("DD-MM-YYYY hh:mm");
};
app.config.globalProperties.$convertDate = convertDateFn;

// User store
import { useUserStore } from "./stores/user";
const userStore = useUserStore();
app.config.globalProperties.$userStore = userStore;

const checkPermissions = (ids) => {
  if (!userStore.roleId || !ids || !ids.length) return false;

  if (ids.indexOf(parseInt(userStore.roleId)) > -1) return true;
  return false;
};
app.config.globalProperties.$checkPermissions = checkPermissions;

import { useNotificationsStore } from "./stores/notifications";
const notificationsStore = useNotificationsStore();
app.config.globalProperties.$notificationsStore = notificationsStore;

import { useThemeStore } from "./stores/theme";
const themeStore = useThemeStore();
app.config.globalProperties.$themeStore = themeStore;

const backendProd = "https://api.sakora.ru"
// const backendDev = "http://localhost:10004"
const backendUrl = backendProd;
app.config.globalProperties.$backendUrl = backendUrl;

// Axios instance
const updateAxiosInstance = () => {
  app.config.globalProperties.$api = axios.create({
    baseURL: `${backendUrl}/api`,
    headers: {
      "Content-Type": "application/json",
      token: app.config.globalProperties.$userStore.token,
    },
    withCredentials: true,
    exposedHeaders: ["set-cookie"],
  });
};
app.config.globalProperties.$updateAxiosInstance = updateAxiosInstance;

updateAxiosInstance();

// Toasts

app.config.globalProperties.$catch = (e, message) => {
  const params = { autoclose: 2000 };

  if (typeof e.response === "undefined") {
    // TODO: Возникает ошибка в чтении $t
    // toast.error(this.$t('main.errors.unexpected_error'), params)
    return;
  }

  if (e.response.status !== 500) {
    toast.error(message, params);
  }

  return;
};

app.config.globalProperties.$toast = (type, message, delay = 0) => {
  const params = {
    autoclose: 2000,
    delay
  };

  if (type === "success") {
    toast.success(message, params);
  }

  if (type === "error") {
    toast.error(message, params);
  }
};

const convertToCurrency = (number) => {
  const currency = app.config.globalProperties.$userStore.currency || "₽";

  if (!number) return 0 + ` ${currency}`;

  return (
    number
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ` ${currency}`
  );
};
app.config.globalProperties.$convertToCurrency = convertToCurrency;

app.mount("#app");
